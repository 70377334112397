import { objectStrAnyType } from "../InterfaceConst"

export const activitySchema = {
    location: '',
    subLocation: '',
    timing: '',
    activity: '',
    conditions: [''],
    cost: '',
    executiveName: '',
    contact: [''],
    email: '',
    website: '',
    photos: '',
    isActive: false,
}
export const agentOfferSliderSchema = {
    image: '',
    name: '',
    caption: '',
    sortOrder: 0,
    isActive: false,
}
export const careerSchema = {
    title: '',
    description: '',
    skills: [''],
    isActive: false,
    formLink: ''
}
export const expenseSchema ={
    name: '',
    category: '',
    description: '',
    purchaseDate: '',
    gstRefund: '',
}
export const flightBookSchema = {
  orderNo: '',
  taxInvoice: '',
  userId: '',
  gstNo: '',
  bookingDate: '',
  flightDate: '',
  pax: '',
  tickets: '',
  airline: '',
  portal: '',
  pnr: '',
  clientCost: '',
  ourCost: '',
  margin: '',
  reference: '',
  isCancelled: false,
  remarks: '',
};
export const hotelSchema = {
  star: '',
  hotelName: '',
  location: '',
  subLocation: '',
  nearBy: [''],
  distance: [''],
  washRoom: '',
  specialFeature: {},
  executiveName: '',
  contact: [''],
  email: '',
  mapLink: '',
  website: '',
  photos: '',
  roomType: '',
  maxPax: '',
  ourCost: [''],
  clientCost: [''],
  agentCost: [''],
  conference: [''],
  isActive: false,
};
export const inquirySchema = {
  userId: '',
  destination: '',
  source: '',
  reference: '',
  pax: '',
  packageDetails: '',
  priceQuoted: '',
  status: '',
  callBackDate: '',
  remarks: '',
  followUps: [''],
};
export const sliderSchema = {
  imageIds: [''],
  name: '',
  description: '',
  isActive: false,
};
export const imageSchema = {
  image: '',
  description: '',
  fileId: '',
  url: '',
  thumbnail: '',
  isActive: false,
};
export const tUserSchema = {
  name: '',
  password: '',
  role: '',
  mobileNo: '',
  email: '',
};
export const tourBookConfirmSchema = {
  tourId: '',
  pickup: '',
  drop: '',
  sightseeing: [''],
  activities: [''],
  driverName: '',
  driverMob: '',
};
export const tourBookSchema = {
  orderNo: '',
  userId: '',
  taxInvoice: '',
  destination: '',
  inquiryId: '',
  hotelId: '',
  bookingDate: '',
  checkIn: '',
  checkOut: '',
  meal: '',
  adult: '',
  child: '',
  type: '',
  clientCost: '',
  ourCost: '',
  pickUpPoint: '',
  pickUpTime: '',
  dropPoint: '',
  dropTime: '',
  extraActivities: [''],
  sightseeing: [''],
  driverName: '',
  driverNumber: '',
  activityConformation: [''],
  remarks: '',
  gstNo: '',
  agentId: '',
  isCancelled: false,
  sendMail: false,
};
export const transactionSchema ={
    name: '',
    email: '',
    mobile: '',
    amount: 2,
    status: '',
}
export const transportSchema = {
    location: '',
    vehicleconst: '',
    price: {},
    tourPrice: {},
    extraCharges: {},
    offTime: '',
    executiveName: '',
    isActive: false,
    contact: [''],
    email: '',
    website: '',
    photos: '',
}
export const tripDetailsSchema = {
    location: '',
    subLocation: '',
    image: [''],
    thumbnail: [''],
    shortDesc: '',
    price: '',
    overview: '',
    dayWise: [['', '']],
    included: [''],
    excluded: [''],
    thingsToCarry: [''],
    cancellationPolicy: [''],
    isActive: false,
}

export const unsubscribeSchema = {
    email: ''
}
export const userSchema = {
    name: '',
    role: '',
    mobileNo: '',
    email: '',
    homeCity: '',
    state: '',
    dob: ''
}

export const itinerarySchema = {
    name: '',
    pdfUrl: '',
    description: '',
    isActive: false,
}


export const adminDataInitial = {
    activity: [],
    agentOfferSlider: [],
    tUser: [],
    career: [],
    flightBook: [],
    sliderImages: [],
    hotel: [],
    inquiry: [],
    transport: [],
    tour: [],
    tripDetails: [],
    unsubscribe: [],
    user: [],
    expense: [],
    tourBookConfirmSchema: [],
    transactionSchema: [],
    error: '',
    loading: false,
    collectionActive: localStorage.getItem('activeCollection') || 'hotel_price',
    bulkError: [],
    itinerary: [],
    image: [],
}

export const agentOrdersDataInitial = {
    agentOrdersData: [{}]
}
export const agentOfferSliderDataInitial = {
    agentOfferSlideData: [{}]
}
