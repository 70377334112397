import React from 'react';
import { Typography } from '@mui/material';
const styleConst = {
  popupTextBold: {
    color: 'black',
    fontWeight: '510',
    fontSize: '1.35rem',
    paddingTop: '1rem',
  },
  popupText: {
    color: 'black',
    fontSize: '1rem',
    paddingTop: '0.5rem',
  },
};
export const TncText = () => {
  return (
    <>
      <Typography
        // variant="h5"
        style={{ color: 'orange', fontWeight: 'bold', fontSize: '2rem' }}
        align="center"
        gutterBottom
      >
        Terms & Conditions
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        Booking Terms & Conditions
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        These Booking Conditions apply to the person making the booking and all
        other persons on whose behalf the booking is done. Please read these
        Booking Conditions carefully. They apply to all bookings made through
        the TRIPYOG website or offline, who are acting as agents on behalf of
        all of the suppliers (including airlines, Sightseeing and Activities
        operator, self-driven car/bike Providers and accommodation providers) in
        your booking.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        You are contracting with the suppliers, whose terms and conditions apply
        to your booking. Copies of those terms and conditions are available upon
        request. These booking conditions govern your relationship with TRIPYOG.
        The person making the booking must be at least 18 years of age and must
        be authorized to make the booking on behalf of the members of the party.
        The person making the booking must also provide a copy of these booking
        conditions to the other members of the party.
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        After Booking (Contract)
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        After we have received your deposit or full payment (if applicable) and
        we have issued a confirmation invoice, a contract will exist between you
        and each of the suppliers, effective from the date printed on your
        invoice. The terms and conditions of each supplier you have booked with
        will apply to your booking. Once the contract(s) is made, the airlines,
        accommodation provider and any other supplier is responsible for
        providing you with what you have booked. TRIPYOG acts as a travel agent,
        We arrange travel services and sell travel-related products on behalf of
        suppliers such as airlines, tour and Activities operators, car hirers
        and accommodation providers. In doing so TRIPYOG gives no warranty nor
        makes any representation regarding the services to be supplied or the
        products purchased by you. Your booking will be subject to the terms and
        conditions applied by the provider of the service or products. You agree
        that if the travel services or products are not supplied in accordance
        with your booking your remedy lies with the service provider (including
        airlines, Sightseeing and Activities operator, self-driven car/bike
        Providers and accommodation providers) and not TRIPYOG.
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        Prices Terms & Conditions
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        Every effort is made to ensure that the prices quoted to you are
        correct; however, TRIPYOG acts only as an agent and relies on
        information provided to us from the suppliers of the travel services and
        products (including airlines, Sightseeing and Activities operator,
        self-driven car/bike Providers and accommodation providers). We will
        bring to your attention any change in price made prior to you completing
        your booking and paying the balance of the price.
      </Typography>
      <Typography style={styleConst.popupTextBold}>Payments</Typography>
      <Typography style={styleConst.popupText} align="justify">
        All payments can be made to TRIPYOG, by cash, Cheque, Demand Draft,
        Credit card, UPI and any other transfer mode. There will be a surcharge
        of 2% if you make the payment by credit card. Which will be added to
        your invoice cost. For bookings which are made within 10 working days of
        tour departure, the full amount will become immediately payable. 100
        Percentage payment has to be made before 10 days of the tour start to
        secure your booking on a confirmed basis.
      </Typography>
      <Typography component="li" style={styleConst.popupText} align="justify">
        <li>
          An additional charge of INR 2000 per person will be levied in case of
          cancellation due to non-payment of the instalment amount as per the
          payment schedule.
        </li>
        <li>
          100% amount needs to be paid for flight tickets in advance for
          issuance of tickets; If flight fare has increased/decreased by the
          time payment reaches us, then the same shall be borne by the client
          and the quotation would be revised accordingly.
        </li>
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        Airline Schedules/Cancellation/Delays
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        It is your responsibility to contact the airline to check if there has
        been any change in the time of departure. We cannot be held responsible
        for any Cancellation or Delay of Flights due to Fog or any other
        technical reason. It is the Airline's responsibility to provide a
        suitable connection in such conditions.
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        Hotel/Accommodation
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        Every Hotel has its own schedule of charges for various extra services
        and property damages. It is your responsibility to settle the bills/fine
        at the time of Amendment or Alterations
        <br />
        If you want to change any part of your booking, we will do all we can to
        help. However, the suppliers or any services providers (including
        airlines, Sightseeing and Activities operator, self-driven car/bike
        Providers and accommodation providers) are under no obligation to make
        any change, although with reasonable notice (usually more than four
        weeks), changes may be possible. If a supplier is able to make a change,
        TRIPYOG will charge an administration or convenience fee per booking. In
        the case of a group booking, no alterations can be made since all the
        arrangements are made in advance to get the group rates with the
        supplier or any service providers (including airlines, Sightseeing and
        Activities operator, self-driven car/bike Providers and accommodation
        providers). Any amendments/additions to the package (dates, inclusions,
        itinerary etc.), will be done at an extra cost to the customer. Changes
        made to the package will be made as per the cancellation policy of the
        original package and the customer has to bear the cost incurred due to
        this.
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        Cancellation Fees
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        <li>
          If cancellations are made 30 days before onwards the start date of the
          trip, 25% of the total tour cost will be charged as cancellation fees.
        </li>
        <li>
          If cancellations are made 15-29 days before the start date of the
          trip, 50% of the total tour cost will be charged as cancellation fees.
        </li>
        <li>
          If cancellations are made within 7-14 days before the start date of
          the trip, 75% of the total tour cost will be charged as cancellation
          fees.
        </li>
        <li>
          If cancellations are made 0-6 days before the start date of the trip,
          100% of the total tour cost will be charged as cancellation fees.
        </li>
        <li>
          No refund will be made in case of mechanical trouble to the vehicle or
          natural calamities like, Landslides, floods, earthquakes, heavy
          snowfall or any other reason beyond the control of TRIPYOG.
        </li>
        <li>
          TRIPYOG shall not be responsible in any matter whatsoever for any
          delay or inconvenience to the passengers during the journey either due
          to the breakdown of the vehicle or any other cause unforeseen
          circumstances beyond the control of TRIPYOG.
        </li>
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        General Terms & Conditions
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        <li>
          Please remember that all special requests like early check-in, late
          check-out, smoking room, non-smoking room, views, floors, king bed,
          twin bed, adjoining and/or interconnecting rooms are strictly subject
          to availability upon arrival and the same cannot be guaranteed prior.
        </li>
        <li>
          Cost of expenses of personal nature at the place of stay, such as
          laundry, telephone calls, room service, alcoholic beverages, mini bar
          etc. not mentioned in the inclusions will have to be borne by the
          traveller.
        </li>
        <li>
          For SIC (i.e. shared) transfers, please reach the SIC pick-up spot at
          least 10 minutes prior to the actual timing – any delay by the
          passenger in reporting on time may lead to loss of service and will
          not be refundable.
        </li>
        <li>
          Customers can not change the itinerary during the trip unless the same
          has been pre-informed and assented to by the Service provider.
        </li>
        <li>
          TRIPYOG does not guarantee hotel services and food quality. However,
          we suggest that in case of any concerns, kindly inform the Duty
          Manager of the hotel immediately so that timely action may be taken.
        </li>
        <li>
          Customers should take responsibility for their own luggage. Cab
          drivers do not provide porter services and cannot be held liable for
          any such loss.
        </li>
        <li>
          Vouchers provided by us against the booking need to be verified by the
          traveller and any discrepancy should be brought to notice within 24
          hours from the time of receiving the vouchers.
        </li>
        <li>
          In case of non-compliance with payment terms, TRIPYOG reserves the
          right to cancel the booking.
        </li>
        <li>
          Refunds can only be given in cases of inclusions mentioned in packages
          confirmed by the customer that are not delivered by the Service
          provider (i.e. airlines, Sightseeing and Activities operator,
          self-driven car/bike Providers and accommodation providers). Refunds
          will not be given in any other case.
        </li>
        <li>
          In case of force majeure cases (such as curfew, riots etc.), TRIPYOG
          shall not be liable for any losses made thereof.
        </li>
        <li>
          Standard check-in timings of hotels are 02:00 pm and check-out is 11
          am. Early check-in and late check-out will be available as per hotel
          availability and hotel rules.
        </li>
        <li>
          Car/driver details will be provided 1 day prior to the travel start
          date.
        </li>
        <li>
          It is mandatory to carry a valid photo identity card for a hotel stay
          (Passport/Driving License/Voter ID card/Aadhar card).
        </li>
        <li>
          Driver allowances start from 08:00 am – 06:00 pm for a particular day.
        </li>
        <li>
          If the vehicle starts before 08:00 am/after 06:00 pm for a particular
          day, extra driver allowances are applicable and need to be paid
          directly to the driver. If the balance payment due is On arrival,
          failure to pay timely on arrival may lead to the booking getting
          cancelled the next day.
        </li>
        <li>
          In case of cab breakdowns, you might need to wait till the issue gets
          sorted out. If it is taking more time, then we will pay for
          sightseeing after receiving verified information.
        </li>
        <li>
          Cancellation amount/charges will be calculated as per the total
          booking amount, irrespective of whether the full booking amount has
          been paid or not.
        </li>
      </Typography>
      <Typography style={styleConst.popupTextBold}>Complaints</Typography>
      <Typography style={styleConst.popupText} align="justify">
        We hope you will have no reason to complain but if you are unhappy with
        any aspect of the services provided you must report it immediately to
        the supplier, or any services providers (including airlines, Sightseeing
        and Activities operator, self-driven car/bike Providers and
        accommodation providers) contact TRIPYOG. We shall attempt to resolve
        the matter immediately. Failure to complain at your destination may mean
        we will be unable to resolve the dispute after you return home. Please
        send your complaint to info@tripyog.in or call +91-7532812944.
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        Note: TRIPYOG reserves the right to alter these terms and conditions at
        any time.
      </Typography>
      <Typography style={styleConst.popupTextBold}>Dispute</Typography>
      <Typography style={styleConst.popupText} align="justify">
        All Cases and Disputes are subject to Patna's Jurisdiction. Your
        contract with TRIPYOG is governed by the laws of Patna and you agree to
        submit to the jurisdiction of the courts of Patna to determine any
        dispute pertaining to the contract.
      </Typography>
    </>
  );
};

export const PrivacyText = () => {
  return (
    <>
      <Typography
        // variant="h5"
        style={{ color: 'orange', fontWeight: 'bold', fontSize: '2rem' }}
        align="center"
        gutterBottom
      >
        Privacy Policy
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        Online Travel Portal That provides information & book Hotels, car
        Rentals, Travel Packages, Activities booking etc. Through Visitors'
        Opinions.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        The following terms govern the collection, use and protection of your
        Personal Information by the Website. This Privacy Policy shall be
        applicable to users or customers who purchase any product or services
        from the Website or visits the Website. By visiting and/ or using the
        Website you have agreed to the following Privacy Policy.
      </Typography>
      <Typography style={styleConst.popupTextBold}>Introduction</Typography>
      <Typography style={styleConst.popupText} align="justify">
        As a registered member of the Website or as a visitor (if applicable and
        as the case may be), you will be entitled to savings and benefits on
        airfares, hotel reservations, holiday packages and car rentals, among
        other travel services. In addition, look forward to receiving monthly
        newsletters and exclusive promotions offering special deals.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        That’s why we’ve provided this Privacy Policy, which sets forth our
        policies regarding the collection, use and protection of the Personal
        Information of those using or visiting the Website.
      </Typography>

      <Typography style={styleConst.popupTextBold}>
        Personal Information
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        Personal Information means and includes all information that can be
        linked to a specific individual or to identify any individual, such as
        name, address, mailing address, telephone number, e – mail address,
        credit card number, cardholder name, expiration date, information about
        the travel, bookings, co-passengers, and any and all details that may be
        requested while any customers visits or uses the website.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        When you browse the Website, TRIPYOG may collect information regarding
        the domain and host from which you access the Internet, the Internet
        Protocol address of the computer or Internet Service Provider you are
        using, and anonymous site statistical data. The Website uses cookie and
        tracking technology depending on the features offered. Personal
        Information will not be collected via cookies and other tracking
        technology; however, if you previously provided personally identifiable
        information, cookies may be tied to such information. Aggregate cookie
        and tracking information may be shared with third parties.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        We encourage you to review our Privacy Policy, and become familiar with
        it, but you should know that we do not sell or rent our customers’
        Personal Information to third parties.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        Please note that we review our Privacy Policy from time to time, and we
        may make periodic changes to the policy in connection with that review.
        Therefore, you may wish to bookmark this page and/or periodically review
        this page to make sure you have the latest version. Regardless of later
        updates, we will abide by the privacy practices described to you in this
        Privacy Policy at the time you provided us with your Personal
        Information.
      </Typography>

      <Typography style={styleConst.popupTextBold}>
        What Personal Information we collect from you and how we use it?
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        When browsing our Website, you are not required to provide any Personal
        Information unless and until you choose to make a purchase, ask a
        queryfor the other services as described below.
      </Typography>

      <Typography style={styleConst.popupTextBold}>
        Making a Purchase
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        In order to purchase travel and/or related services through our Website,
        you must provide us with certain Personal Information such as your name,
        credit card number and expiration date, credit card billing address,
        telephone number, e – mail address and the name or names of the
        person(s) traveling (if not you). We may also ask you for other Personal
        Information, such as your frequent traveller numbers. We require this
        information to process, fulfill and confirm your reservations and
        transactions and keep you informed of each transaction’s status. If you
        are making a reservation for one or more travellers other than yourself,
        you must confirm and represent that each of these other travellers have
        agreed, in advance, that you may disclose their Personal Information to
        us.
      </Typography>

      <Typography style={styleConst.popupTextBold}>Online Surveys</Typography>
      <Typography style={styleConst.popupText} align="justify">
        TRIPYOG values opinions and comments from members, so we frequently
        conduct online surveys. Participation in these surveys is entirely
        optional. Typically, the information is aggregated, and used to make
        improvements to the Website and its services and to develop appealing
        content, features and promotions for Website members. Survey
        participants are anonymous unless otherwise stated in the survey.
      </Typography>

      <Typography style={styleConst.popupTextBold}>
        Promotions & Sweepstakes
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        TRIPYOG frequently sponsors promotions and sweepstakes to give Website
        members the opportunity to win great travel and travel – related prizes.
        Information collected by us for such activities may include contact
        information and survey questions. We use contact information to notify
        contest winners and survey information to develop promotions and product
        improvements to the Website.
      </Typography>

      <Typography style={styleConst.popupTextBold}>Cookies</Typography>
      <Typography style={styleConst.popupText} align="justify">
        “Cookies” are small pieces of information that are stored by your
        browser on your computer’s hard drive. There are many myths circulating
        about cookies, but you should know that cookies are only read by the
        server that placed them, and are unable to do such things as run
        programs on your computer, plant viruses or harvest your Personal
        Information. The use of cookies is very common on the Internet and
        TRIPYOG’s use of cookies is similar to that of such Websites like
        Yahoo!, CNN.com and other reputable online companies.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        First and foremost, you can rest assured that no personally identifiable
        information (“PII”) about you (e.g., name, address, etc.) is gathered or
        stored in the cookies placed by the Website and, as a result, none can
        be passed on to any third parties.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        Cookies allow us to serve you better and more efficiently, and to
        personalize your experience at our Website. TRIPYOG uses cookies to
        personalize your experience on the Website, and with respect to
        advertisements. As to the former, these types of cookies allow you to
        log in without having to type your log – in name each time (only your
        password is needed. We may also use such cookies to display an
        advertisement to you while you are on the Website or to send you a “Best
        Day to Buy” email (or similar emails – assuming you have not opted out
        of receiving such emails) focusing on destinations in which we think you
        might be interested. None of this information is passed to any third
        party, and is used solely by us to provide you with a better user
        experience on the Website.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        The third – party advertising companies or advertisement providers may
        also employ technology that is used to measure the effectiveness of ads.
        Any such information is anonymous. They may use this anonymous
        information about your visits to this and other sites in order to
        provide advertisements about goods and services of potential interest to
        you. No PII is collected during this process. The information is
        anonymous, and does not link online actions to an identifiable person.
        If you would like more information, and want to explore the option of
        not having this information collected by third – party advertisers.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        Most Web browsers automatically accept cookies. Of course, by changing
        the options on your web browser or using certain software programs, you
        can control how and whether cookies will be accepted by your browser.
        TRIPYOG supports your right to block any unwanted Internet activity,
        especially that of unscrupulous websites. However, blocking TRIPYOG
        cookies may disable certain features on the Website, and may make it
        impossible to purchase or use certain services available on the Website.
        Please note that it is possible to block cookie activity from certain
        websites while permitting cookies from websites you trust, like TRIPYOG
        Website.
      </Typography>

      <Typography style={styleConst.popupTextBold}>Other</Typography>
      <Typography style={styleConst.popupText} align="justify">
        From time to time we may add or enhance services available on the
        Website. To the extent these services are provided, and used by you, we
        will use the information you provide to facilitate the service
        requested. For example, if you email us with a question, we will use
        your email address, name, nature of the question, etc. to respond to
        your question. We may also store such information to assist us in making
        the Website better and easier to use.
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        With whom (if anyone) your Personal Information is shared?
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        When you reserve or purchase travel services through the Website, we
        must provide certain of your Personal Information to the airline, hotel,
        car – rental agency, travel agency or other involved third party to
        enable the successful fulfillment of your travel arrangements. However,
        we do not sell or rent individual customer names or other Personal
        Information to third parties. We use non – personally identifiable
        information in aggregate form to build higher quality, more useful
        online services by performing statistical analysis of the collective
        characteristics and behavior of our customers and visitors, and by
        measuring demographics and interests regarding specific areas of the
        Website. Occasionally, TRIPYOG will hire a third party to act on our
        behalf for projects such as market – research surveys and contest –
        entry processing and will provide information to these third parties
        specifically for use in connection with these projects. The information
        we provide to such third parties is protected by a confidentiality
        agreement and is to be used solely for completing the specific project.
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        How can you opt-out of receiving our promotional emails?
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        At any time, you may unsubscribe from our Travel Deals, or other
        newsletter or similar services. As a member or promotion/sweepstakes
        entrant, you will occasionally receive email updates from us about
        special offers, new TRIPYOG services, and other noteworthy items. We
        hope you will find these updates interesting and informative. Of course,
        if you would rather not receive them, please click on the “unsubscribe”
        link. TRIPYOG reserves the right to limit membership to those who will
        accept emails. Members will be notified via email prior to any actions
        taken.
      </Typography>

      <Typography style={styleConst.popupTextBold}>
        What Other information should I know about my privacy?
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        Website contains links to other websites. Please note that when you
        click on one of these links, you are entering another website for which
        TRIPYOG has no responsibility. We encourage you to read the privacy
        statements of all such sites as their policies may be materially
        different from this Privacy Policy. Of course, you are solely
        responsible for maintaining the secrecy of your passwords, and your
        Website membership account information. Please be very careful,
        responsible, and alert with this information, especially whenever you’re
        online.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        In addition to the circumstances described above, TRIPYOG may disclose
        the Website member information if required to do so by law, court order,
        as requested by other government or law enforcement authority, or in the
        good faith belief that disclosure is otherwise necessary or advisable
        including, without limitation, to protect the rights or properties of
        TRIPYOG or any or all of its affiliates, associates, employees,
        directors or officers or when we have reason to believe that disclosing
        the information is necessary to identify, contact or bring legal action
        against someone who may be causing interference with our rights or
        properties, whether intentionally or otherwise, or when anyone else
        could be harmed by such.
      </Typography>
    </>
  );
};

export const CancellationText = () => {
  return (
    <>
      <Typography
        // variant="h5"
        style={{ color: 'orange', fontWeight: 'bold', fontSize: '2rem' }}
        align="center"
        gutterBottom
      >
        Cancellation Policy
      </Typography>

      <Typography style={styleConst.popupTextBold}>
        Tour Cancellation
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        <li>
          If cancellations are made 30 days before onwards the start date of the
          trip, 25% of the total tour cost will be charged as cancellation fees.
        </li>
        <li>
          If cancellations are made 15-29 days before the start date of the
          trip, 50% of the total tour cost will be charged as cancellation fees.
        </li>
        <li>
          If cancellations are made within 7-14 days before the start date of
          the trip, 75% of the total tour cost will be charged as cancellation
          fees.
        </li>
        <li>
          If cancellations are made 0-6 days before the start date of the trip,
          100% of the total tour cost will be charged as cancellation fees.
        </li>
        <li>
          No refund will be made in case of mechanical trouble to the vehicle or
          natural calamities like, Landslides, floods, earthquakes, heavy
          snowfall or any other reason beyond the control of TRIPYOG.
        </li>
        <li>
          TRIPYOG shall not be responsible in any matter whatsoever for any
          delay or inconvenience to the passengers during the journey either due
          to the breakdown of the vehicle or any other cause unforeseen
          circumstances beyond the control of TRIPYOG.
        </li>
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        Airline Schedules/Cancellation/Delays
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        It is your responsibility to contact the airline to check if there has
        been any change in the time of departure. We cannot be held responsible
        for any Cancellation or Delay of Flights due to Fog or any other
        technical reason. It is the Airline's responsibility to provide a
        suitable connection in such conditions.
      </Typography>
      <Typography style={styleConst.popupTextBold}>Refund Policy</Typography>
      <Typography style={styleConst.popupText} align="justify">
        The refund shall be processed within 5-15 working days from the date of
        the cancellation request approved, after receiving back to back refund
        from the concerned Vendor. Payment will be refunded back into the same
        original payment instrument like the same credit card, UPI or bank
        account from which it was paid by the traveller. Please note that the
        time taken for the refund to reflect in your account may vary based on
        your payment method and financial institution.
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        Hotel/Accommodation
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        Every Hotel has its own schedule of charges for various extra services
        and property damages. It is your responsibility to settle the bills/fine
        at the time of Amendment or Alterations
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        If you want to change any part of your booking, we will do all we can to
        help. However, the suppliers or any services providers (including
        airlines, Sightseeing and Activities operator, self-driven car/bike
        Providers and accommodation providers) are under no obligation to make
        any change, although with reasonable notice (usually more than four
        weeks), changes may be possible. If a supplier is able to make a change,
        TRIPYOG will charge an administration or convenience fee per booking. In
        the case of a group booking, no alterations can be made since all the
        arrangements are made in advance to get the group rates with the
        supplier or any service providers (including airlines, Sightseeing and
        Activities operator, self-driven car/bike Providers and accommodation
        providers). Any amendments/additions to the package (dates, inclusions,
        itinerary etc.), will be done at an extra cost to the customer. Changes
        made to the package will be made as per the cancellation policy of the
        original package and the customer has to bear the cost incurred due to
        this.
      </Typography>

      <Typography style={styleConst.popupTextBold}>
        TRIPYOG reserves the right to make changes to these materials at any
        time without notice
      </Typography>
    </>
  );
};

export const DisclaimerText = () => {
  return (
    <>
      <Typography
        // variant="h5"
        style={{ color: 'orange', fontWeight: 'bold', fontSize: '2rem' }}
        align="center"
        gutterBottom
      >
        Disclaimer
      </Typography>

      <Typography style={styleConst.popupText} align="justify">
        The information provided by TRIPYOG on our website, brochures, or other
        promotional materials is for general informational purposes only. We
        make no guarantees, representations or warranties of any kind, express
        or implied, about the accuracy, reliability, suitability, or
        availability with respect to the website or the information, products,
        services, or related graphics contained on the website for any purpose.
        Any reliance you place on such information is therefore strictly at your
        own risk.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        TRIPYOG assumes no responsibility for any errors or omissions in the
        content of the website or other materials provided. We do not endorse,
        approve, or guarantee any third-party products or services advertised on
        our website. Any links to third-party websites are provided solely for
        your convenience and do not imply endorsement by TRIPYOG.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        TRIPYOG is not liable for any loss or damage, including without
        limitation, indirect or consequential loss or damage, or any loss or
        damage whatsoever arising from loss of data or profits arising out of,
        or in connection with, the use of this website or any services provided
        by TRIPYOG.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        TRIPYOG reserves the right to make changes to these materials at any
        time without notice. By using our services, you agree to these terms and
        conditions, and you acknowledge that TRIPYOG is not liable for any
        damages or losses arising from your use of our services.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        Travel involves certain inherent risks and dangers, and we recommend
        that you take all necessary precautions to ensure your safety while
        traveling. We strongly advise you to obtain comprehensive travel
        insurance to cover any unexpected circumstances that may arise during
        your trip.
      </Typography>
      <Typography style={styleConst.popupTextBold}>
        If you have any questions about this disclaimer or our services, please
        contact us at “+91 7532812944 or mail us info@tripyog.in”
      </Typography>
    </>
  );
};
export const AboutUsText = () => {
  return (
    <>
      <Typography
        // variant="h5"
        style={{ color: 'orange', fontWeight: 'bold', fontSize: '2rem' }}
        align="center"
        gutterBottom
      >
        About Us
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        Remember those school days when teachers would ask, “What will you
        become in the future?” Most students would eagerly reply, “Doctor” or
        “Engineer”, unaware that traveling could also be a career. With an aim
        to make travel affordable and accessible using technology, Tripyog was
        founded in 2022. Our journey began with a dream to change the way people
        explore the world, ensuring that unforgettable travel experiences are
        within everyone's reach. Tripyog has served over 2,000 happy travelers
        in just two years. Our commitment to excellence has helped us build a
        vibrant community of 40,000+ followers on social media, making us a
        trusted name in the travel industry. At Tripyog, we're not just another
        travel company—we're a movement dedicated to redefining how people
        experience travel. We are a youthful, hardworking crew fueled by
        passion, purpose, and a relentless drive to make every journey smooth,
        memorable, and extraordinary. With a team of skilled professionals, each
        bringing expertise from their specialized fields, we work tirelessly
        around the clock to ensure your trips are nothing short of
        unforgettable. From the moment you book to the cherished post-trip
        memories, we're here every step of the way.
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        <strong>What makes us special?</strong>
      </Typography>
      <Typography style={styleConst.popupText} align="justify">
        • Innovative software solutions: Our proprietary travel software, which
        is currently in its beta phase, includes tools like the Goa package
        calculator that help you customize trips with ease.
      </Typography>

      <Typography style={styleConst.popupText} align="justify">
        • Exceptional after-sales service: We employ dedicated travel managers
        to resolve any issues during your trip, ensuring seamless communication
        and a stress-free experience.
      </Typography>

      <Typography style={styleConst.popupText} align="justify">
        • Personalized post-trip engagement: We go beyond the ordinary by
        sending personalized travel photos to our customers, building lasting
        relationships beyond the trip.
      </Typography>

      <Typography style={styleConst.popupText}>
        <strong>
          Join the Tripyog family today and discover how travel should truly
          feel!
        </strong>
      </Typography>
    </>
  );
};
