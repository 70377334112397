import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { imageApiEmptyData } from '../Common/Constant/StaticData/APIEmptyData';
import { imageType } from './ImageView';
import { getAllAPICall } from '../ApiBackend/AgentApiCal';
import { useSelector } from 'react-redux';
import { rootStoreType } from '../../Redux/reducers';
import { Height } from '@mui/icons-material';
import { apiLoginToken } from '../ApiBackend/ApiCal';

type imageLoaderType = { src: string; width?: number; quality?: number };
type imagePropsType = {
  imageId: string;
  width?: number;
  quality?: number;
  height?: number;
  isThumbnail?: boolean;
};

export const imageKitLoader = ({ src, width, quality }: imageLoaderType) => {
  if (!src) return '';
  // console.log('src: ', src);
  if (src[0] === '/') src = src.slice(1);
  const params = [`w-${width}`];
  if (quality) {
    params.push(`q-${quality}`);
  }
  const paramsString = params.join(',');
  let urlEndpoint = 'https://ik.imagekit.io/tripyog';
  if (urlEndpoint[urlEndpoint.length - 1] === '/')
    urlEndpoint = urlEndpoint.substring(0, urlEndpoint.length - 1);
  return `${urlEndpoint}/${src}?tr=${paramsString}`;
};

const ImageCustomSize = ({
  imageId,
  width,
  height,
  quality,
  isThumbnail,
}: imagePropsType) => {
  const [imageDataUrl, setImageDataUrl] =
    useState<imageType>(imageApiEmptyData);
  // const apiToken = useSelector((state: rootStoreType) => state.auth.authToken);

  const fetchImageData = async (id: string) => {
    const genToken = await apiLoginToken();
    const res = await getAllAPICall({
      apiEndPoint: `/api/v1/image/${id}`,
      tokenId: genToken!,
    });
    setImageDataUrl(res);
  };
  useEffect(() => {
    fetchImageData(imageId);
  }, [imageId]);

  //   const imageUrl = useLazyLoadQuery<imageByIdType>(
  //     userFetchImageByIdQuery,
  //     { imageId: imageId ? imageId : '' },
  //     { fetchPolicy: 'store-or-network' }
  //   );

  //   useEffect(() => {
  //     if (imageUrl.imageById) {
  //       setImageDataUrl(imageUrl.imageById as imageSchemaType);
  //     }
  //   }, [imageUrl]);

  return (
    <>
      {imageDataUrl && imageDataUrl._id !== '' ? (
        <img
          src={
            isThumbnail
              ? imageDataUrl.thumbnail
              : imageKitLoader({ src: imageDataUrl.url, width, quality })
          }
          alt={imageDataUrl.name}
          width="100%%"
          style={{ objectFit: 'cover', height: '100%' }}
        />
      ) : (
        // Display blur container
        <Box
          sx={{
            width: '100%',
            height: 256,
            backgroundColor: '#D3D3D3',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              opacity: 0,
              '&:hover': { opacity: 1 },
              transition: 'opacity 0.3s',
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ImageCustomSize;

